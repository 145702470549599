<template>
  <v-container>
    <div class="text-center">
      <v-toolbar dense floating>
        <v-chip class="ma-2" color="primary" text-color="white">
          {{ solicitacoes.length }} solicitação(oẽs)
        </v-chip>
        <v-chip
          v-show="me.role.type == 'requester'"
          class="ma-2"
          color="success"
          text-color="white"
          :to="{ path: 'solicitacao' }"
        >
          Solicitar demanda
        </v-chip>
      </v-toolbar>
    </div>

    <div class="text-center">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        v-show="dataLoading"
      ></v-progress-circular>
    </div>

    <div class="d-flex flex-column-reverse align-center text-start">
      <v-card
        v-for="item in solicitacoes"
        :key="item.id"
        width="80%"
        ml-auto
        mr-auto
      >
        <v-card-title>
          <h3>{{ item.title }}</h3>
          <v-chip small :color="pickColor(item.status)">
            {{ item.status }}
          </v-chip>
        </v-card-title>
        <v-card-text>
          <p>{{ item.body }}</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          Solicitado por <strong> {{ item.requester.name }} </strong> as
          <strong> {{ convertDate(item.created_at) }} </strong>
          classificado como <strong> {{ item.rank.name }} </strong>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text
          >Comentario: {{ item.comment || "Sem comentario" }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text> {{ item.files.length }} arquivos </v-card-text>
        <ListaArquivos :list="item.files" />
        <v-card-actions class="align-center justify-center">
          <v-btn
            v-if="me.role.type == 'requester'"
            @click="editarModal(item)"
            color="primary"
          >
            Editar
          </v-btn>
          <v-btn v-else color="success" @click="editarStatus(item)">
            Atualizar status
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <div class="text-center ">
      <v-dialog v-model="dialog" width="800">
        <v-card max-width="800" class="text-center">
          <h3>Atualizar Solicitação</h3>
          <v-form ref="form" lazy-validation>
            <v-text-field
              :rules="assuntoRules"
              label="Assunto"
              v-model="modelData.title"
            >
            </v-text-field>

            <v-textarea
              :rules="corpoRules"
              filled
              label="Corpo"
              v-model="modelData.body"
            >
            </v-textarea>
            <v-btn color="primary" @click="editar"> Enviar </v-btn>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogStatus" width="800">
        <v-card max-width="800" class="text-center">
          <h3>Atualizar status</h3>
          <v-card width="500" class="ml-auto mr-auto pt-4 pb-4" elevation="0">
            <v-form ref="status" lazy-validation>
              <v-select :items="statusOptions" v-model="statusModal"></v-select>
              Adicionar comentario
              <v-textarea v-model="comentarioModal"> </v-textarea>
              <v-btn color="primary" @click="atualizarStatus">
                Atualizar
              </v-btn>
            </v-form>
          </v-card>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import instanceAxios from "./../../plugins/axios";
import { mapGetters } from "vuex";
import ClienteService from "../../services/Cliente/ClienteService";
import ListaArquivos from "@/components/Listas/ListaArquivos";
export default {
  components: {
    ListaArquivos,
  },

  data() {
    return {
      statusOptions: ["Solicitado", "Aceito", "Rejeitado", "Em analise"],
      dialog: false,
      comentarioModal: "",
      dialogStatus: false,
      dataLoading: true,
      solicitacoes: [],
      clientes: [],
      statusModal: "",
      doc: "",
      cliente_id: undefined,
      ClienteService: new ClienteService(""),
      modelData: { assunto: "", corpo: "" },

      assuntoRules: [
        (v) => !!v || "Assunto é obrigatorio",
        (v) =>
          (v && v.length >= 5) || "O assunto deve ter mais de 5 characteres",
      ],
      corpoRules: [
        (v) => !!v || "O corpo é obrigatorio",
        (v) => (v && v.length >= 5) || "O corpo deve ter mais de 5 characteres",
      ],
    };
  },
  async mounted() {
    this.getSolicitacoes();
    // await this.showClientes();
  },
  methods: {
    async getSolicitacoes() {
      this.dataLoading = true;

      const response = await instanceAxios.get("requesters");
      this.solicitacoes = response.data;

      this.dataLoading = false;
    },
    pickColor(status) {
      var color = "primary";
      switch (status) {
        case "Solicitado":
          color = "primary";
          break;
        case "Aceito":
          color = "success";
          break;
        case "Rejeitado":
          color = "error";
          break;
        case "Em analise":
          color = "warning";
          break;
      }

      return color;
    },
    async atualizarStatus() {
      try {
        await instanceAxios.put(`requesters/${this.doc}`, {
          status: this.statusModal,
          comment: this.comentarioModal,
        });

        if (this.statusModal == "Aceito") {
          let solicitacao = this.solicitacoes.find(
            (element) => element.id == this.doc
          );

          console.log("solic");
          console.log(solicitacao);
          this.$store.dispatch("solicitacao", solicitacao);

          this.$router.push({ path: "formulario" });
        }

        this.dialogStatus = false;
      } catch (error) {
        console.log(error);
      }
    },
    async editar() {
      await instanceAxios.put(`requesters/${this.doc}`, this.modelData);

      this.getSolicitacoes();
      this.this.dialog = false;
    },
    editarModal(item) {
      this.modelData.title = item.title;
      this.modelData.body = item.body;

      this.doc = item.id;
      this.customer_id = item.customer_id;

      this.dialog = true;
    },
    editarStatus(item) {
      console.log(item);
      this.statusModal = item.status;
      this.doc = item.id;
      this.dialogStatus = true;
    },
    clienteNome(cliente_id) {
      return "ola" + cliente_id;
      // console.log(this.clientes);
      // const cliente = this.clientes.find((element) => {
      //   if (element.id == cliente_id) {
      //     return true;
      //   }
      // });

      // return cliente.nome;
    },
    async showClientes() {
      try {
        const clientes = await this.UserService.index("customers");
        clientes.map((element) => {
          this.clientes.push({
            id: element.id,
            nome: element.nome_cliente,
          });
        });
      } catch (exception) {
        //ToastService("Não foi possível consultar os dados.", "error");
      } finally {
        this.loading = false;
      }
    },

    convertDate(created_at) {
      var date = new Date(created_at);

      return date.toLocaleString("pt-BR", {
        hour: "numeric", // numeric, 2-digit
        minute: "numeric", // numeric, 2-digit
        day: "2-digit", // numeric, 2-digit
        year: "2-digit", // numeric, 2-digit
        month: "2-digit", // numeric, 2-digit, long, short, narrow
      });
    },
  },
  computed: {
    ...mapGetters({
      me: "getMe",
      permissions: "getPermissions",
      solicitacao: "getSolicitacao",
    }),
  },
};
</script>

<style></style>
