<template>
  <div>
    <v-list>
      <v-list-item v-for="item in list" :key="'item' + item.id">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn color="primary" @click="downloadFile(item)">
            Baixar
            <v-icon small color="white" class="ml-2"> fas fa-download </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: function() {
        return [];
      },
      required: false,
    },
  },

  methods: {
    downloadFile(item) {
     window.open(process.env.VUE_APP_DOWLOAD + item.path, '_blank');
    },
  },
};
</script>

<style></style>
